import { GameStatus } from "@/prisma/schema/mysql";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { TFunction } from "i18next";
import { FC } from "react";
import { MdPlusOne } from "react-icons/md";
import { useTranslation } from "react-i18next";

const statusStyles = {
  PROCESSING: {
    bgColor: "bg-slate-100",
    borderColor: "border-amber-500",
    textColor: "text-amber-500",
    iconColor: "fill-amber-500",
  },
  COMPLETED: {
    bgColor: "bg-slate-100",
    borderColor: "border-cyan-500",
    textColor: "text-cyan-500",
    iconColor: "fill-cyan-500",
  },
  SUCCESS: {
    bgColor: "bg-slate-100",
    borderColor: "border-green-500",
    textColor: "text-green-500",
    iconColor: "fill-green-500",
  },
  FAILED: {
    bgColor: "bg-slate-100",
    borderColor: "border-red-500",
    textColor: "text-red-500",
    iconColor: "fill-red-500",
  },
  REFUNDED: {
    bgColor: "bg-slate-100",
    borderColor: "border-emerald-700",
    textColor: "text-emerald-700",
    iconColor: "text-amber-500",
  },
};

const ProcessingStatus = (t: TFunction) => (
  <div
    className={`flex justify-center h-9 w-full p-2 ${statusStyles.PROCESSING.bgColor} rounded border ${statusStyles.PROCESSING.borderColor} items-center gap-3`}
  >
    <div className="justify-start items-center gap-2 flex">
      <div className="w-5 h-5 relative">
        <ExclamationCircleIcon className={statusStyles.PROCESSING.iconColor} />
      </div>
      <div
        className={`text-sm font-normal leading-tight ${statusStyles.PROCESSING.textColor}`}
      >
        {t("gameCard.status.processing")}
      </div>
    </div>
  </div>
);

const CompletedStatus = (t: TFunction) => (
  <div
    className={`flex justify-center h-9 w-full p-2 ${statusStyles.COMPLETED.bgColor} rounded border ${statusStyles.COMPLETED.borderColor} items-center gap-3`}
  >
    <div className="justify-start items-center gap-2 flex">
      <div className="w-5 h-5 relative">
        <CheckCircleIcon className={statusStyles.COMPLETED.iconColor} />
      </div>
      <div
        className={`text-sm font-normal leading-tight ${statusStyles.COMPLETED.textColor}`}
      >
        {t("gameCard.status.completed")}
      </div>
    </div>
  </div>
);

const SuccessStatus = (t: TFunction) => (
  <div
    className={`flex justify-center h-9 w-full p-2 ${statusStyles.SUCCESS.bgColor} rounded border ${statusStyles.SUCCESS.borderColor} items-center gap-3`}
  >
    <div className="justify-start items-center gap-2 flex">
      <div className="w-5 h-5 relative">
        <CheckCircleIcon className={statusStyles.SUCCESS.iconColor} />
      </div>
      <div
        className={`text-sm font-normal leading-tight ${statusStyles.SUCCESS.textColor}`}
      >
        {t("gameCard.status.success")}
      </div>
    </div>
  </div>
);

const FailedStatus = (t: TFunction) => (
  <div
    className={`flex justify-center h-9 w-full p-2 ${statusStyles.FAILED.bgColor} rounded border ${statusStyles.FAILED.borderColor} items-center gap-3`}
  >
    <div className="flex items-center border-r border-slate-400 pr-2">
      <div className="justify-start items-center gap-2 flex">
        <div className="w-5 h-5 relative">
          <ExclamationTriangleIcon className={statusStyles.FAILED.iconColor} />
        </div>
        <div
          className={`text-sm font-normal leading-tight ${statusStyles.FAILED.textColor}`}
        >
          {t("gameCard.status.failed")}
        </div>
      </div>
    </div>
    <div className="text-blue-500 text-sm font-normal leading-tight hover:underline">
      {t("gameCard.status.retry")}
    </div>
  </div>
);

const RefundedStatus = (t: TFunction) => (
  <div
    className={`flex justify-center h-9 w-full p-2 ${statusStyles.REFUNDED.bgColor} rounded border ${statusStyles.REFUNDED.borderColor} items-center gap-3`}
  >
    <div className="justify-start items-center gap-2 flex">
      <div className="flex gap-1">
        <SparklesIcon
          className={`w-4 h-4 ${statusStyles.REFUNDED.iconColor}`}
        />
        <MdPlusOne className={`w-4 h-4 ${statusStyles.REFUNDED.iconColor}`} />
      </div>
      <div
        className={`text-sm font-normal leading-tight ${statusStyles.REFUNDED.textColor}`}
      >
        {t("gameCard.status.refunded")}
      </div>
    </div>
  </div>
);

export { statusStyles };

interface GamestatusPopoverProps {
  status: GameStatus;
  handleResubmit: () => void;
  absolute?: boolean;
  refunded?: boolean;
}

export const GamestatusPopover: FC<GamestatusPopoverProps> = ({
  status,
  handleResubmit,
  absolute,
  refunded = false,
}) => {
  const { t } = useTranslation("common");
  let renderComp = (t: TFunction) => <></>;
  let renderExtra = (t: TFunction) => <></>;
  if (status === "FAILED") renderComp = FailedStatus;
  if (status === "PROCESSING") renderComp = ProcessingStatus;
  if (status === "SUCCESS") renderComp = SuccessStatus;
  if (status === "COMPLETED") renderComp = CompletedStatus;
  if (refunded) renderExtra = RefundedStatus;

  const handleClick = (e: any) => {
    if (status === "FAILED") {
      e.preventDefault();
      handleResubmit();
    }
  };

  return (
    <div
      className={clsx(
        "w-fit p-1 gap-1 flex flex-col",
        absolute && "absolute flex flex-col bottom-0 w-full ",
      )}
      onClick={handleClick}
    >
      {renderComp(t)}
      {renderExtra(t)}
    </div>
  );
};
