import { useEffect, useMemo, useRef } from "react";

type ThrottledFunction<T extends (...args: any[]) => any> = T & {
  flush: () => void;
};

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  limit: number,
): ThrottledFunction<T> {
  let lastFunc: NodeJS.Timeout;
  let lastRan: number;

  const throttledFunction = function (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ) {
    const context: any = this;

    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  } as ThrottledFunction<T>;

  throttledFunction.flush = () => {
    clearTimeout(lastFunc);
    if (Date.now() - lastRan < limit) {
      func();
      lastRan = Date.now();
    }
  };

  return throttledFunction;
}

export function useThrottlev2<T extends (...args: any[]) => any>(
  callback: T,
  limit: number,
): ThrottledFunction<T> {
  const ref = useRef<T | undefined>();
  ref.current = callback;

  const throttledCallback = useMemo(() => {
    const func = (...args: Parameters<T>) => {
      return ref.current?.(...args);
    };

    return throttle(func, limit);
  }, [limit]);

  useEffect(() => {
    const handleUnload = () => {
      throttledCallback.flush();
    };
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      throttledCallback.flush();
    };
  }, [throttledCallback]);

  return throttledCallback as ThrottledFunction<T>;
}
