import axios from "axios";
import { logger } from "@/lib/logging/logger";
import {
  filterSchemaCollections,
  filterSchemaTypeCollections,
  parseAllParams,
  possibleOrderByValuesCollections,
} from "@/lib/validators/queryParamsFiltersTest";
import { CollectionWithGamesPreview } from "@/types/FullCollectionType";

export type TpossibleOrderByValuesCollections =
  (typeof possibleOrderByValuesCollections)[number];

export function parseCollectionFilter(
  orderBy: string | undefined,
): orderBy is TpossibleOrderByValuesCollections {
  return possibleOrderByValuesCollections.includes(
    orderBy as TpossibleOrderByValuesCollections,
  );
}

interface CollectionsQueryResult {
  count: number; //total items
  collections: CollectionWithGamesPreview[]; //a page of items
}

type CollectionsQueryParams = Pick<
  filterSchemaTypeCollections,
  "page" | "pageSize" | "orderBy" | "order" | "name" | "description"
>;

export async function queryCollections(
  baseUrl: string,
  queryParams: CollectionsQueryParams,
): Promise<CollectionsQueryResult> {
  const params = parseAllParams(filterSchemaCollections, queryParams);
  try {
    const { data } = await axios.get<CollectionsQueryResult>(baseUrl, {
      params,
    });
    return data;
  } catch (error) {
    logger.error(`GET ${baseUrl}`, error);
    return { count: 0, collections: [] };
  }
}

export const createFavoritesCollectionWithGame = async (
  chessGameId: string,
) => {
  // This does not work, lol
  // create a favorites collection and initialize it to a game
  const response = await fetch("/api/collections", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: "Favorites",
      description: `My favorite games`,
      gameId: chessGameId,
    }),
  });
  return (await response.json()) as CollectionWithGamesPreview;
};

export const createFavoritesCollection = async () => {
  // create a favorites collection and initialize it to a game
  const response = await fetch("/api/collections", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: "Favorites",
      description: `My favorite games`,
    }),
  });
  return (await response.json()) as CollectionWithGamesPreview;
};

export const removeGameFromCollection = async (
  collectionId: string,
  gameId: string,
): Promise<void> => {
  const response = await fetch(`/api/collections/${collectionId}`, {
    method: "PATCH",
    cache: "no-cache",
    body: JSON.stringify({
      games: {
        remove: [gameId],
      },
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to remove game from collection");
  }
};

export const addGameToCollection = async (
  collectionId: string,
  gameId: string,
): Promise<CollectionWithGamesPreview> => {
  const response = await fetch(`/api/collections/${collectionId}`, {
    method: "PATCH",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      games: {
        add: [gameId],
      },
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to add game to collection");
  }

  return response.json();
};
