"use client";

import clsx from "clsx";

function accuracyStyle(accuracy: number): React.CSSProperties | undefined {
  return { width: `${Math.round(accuracy * 100)}%` };
}

function accuracyColor(accuracy: number, override?: boolean): string {
  if (override) return "bg-green-500";
  if (accuracy < 0.5) {
    return "bg-red-500";
  }
  if (accuracy < 0.75) {
    return "bg-amber-500";
  } else {
    return "bg-green-500";
  }
}

function textAccuracyColor(accuracy: number, override?: boolean): string {
  if (override) return "text-white";
  if (accuracy < 0.75) {
    return "text-slate-600";
  } else {
    return "text-white";
  }
}

export interface AccuracyBarProps {
  ocrAccuracy?: number;
  override?: boolean;
}

export function AccuracyBar({ ocrAccuracy, override }: AccuracyBarProps) {
  if (ocrAccuracy !== 0 && !ocrAccuracy) {
    return <div></div>;
  }
  return (
    <div className="flex gap-1 w-[100px] h-[24px]">
      <div className="flex items-center content-center w-full h-full relative bg-slate-300 rounded-full dark:bg-gray-700">
        <div
          className={`absolute h-full left-0 top-0 rounded-full shadow ${accuracyColor(ocrAccuracy, override)}`}
          style={accuracyStyle(ocrAccuracy)}
        ></div>
        <p
          className={clsx(
            "text-xs drop-shadow-md font-semibold text-center mx-auto",
            textAccuracyColor(ocrAccuracy, override),
          )}
        >
          {Math.round(ocrAccuracy * 100)}%
        </p>
      </div>
    </div>
  );
}

AccuracyBar.Name = "AccuracyBar";
